import { get, put, del, post, ApiError } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import APIError from 'app/shared-components/error/APIError';
import SecurityError from 'app/shared-components/error/SecurityError';
export const clientVersion= 103; //Manually update before deployment with any changes that need to force refresh


export async function apiGet(apiName, path, options){
   const restOperation = get({
	    apiName,
	    path,
	    options: await addAuthorization(apiName, options),
	  });
   return await execute(restOperation);
}

export async function apiPut(apiName, path, options){
   const restOperation = put({
	    apiName,
	    path,
	    options: await addAuthorization(apiName, options),
	  });
   return await execute(restOperation);
}

export async function apiDel(apiName, path, options){
   const restOperation = del({
	    apiName,
	    path,
	    options: await addAuthorization(apiName, options),
	  });
   return await execute(restOperation);
}

export async function apiPost(apiName, path, options){
   const restOperation = post({
	    apiName,
	    path,
	    options: await addAuthorization(apiName, options),
	  });
	return await execute(restOperation);
}


async function execute(restOperation){
	try {
	  const { body, headers } = await restOperation.response;
	  const version= headers['x-client-version'];
     if (version > clientVersion) { // if server version newer
        console.log("Newer client version exists, forcing refresh at next route change.");
        if (window.localStorage) window.localStorage.setItem('version-update-needed', 'true'); // set version update item so can refresh app later
     };
	  return await body.json();
	} catch (error) {
	  if (error instanceof ApiError) {
	    if (error.response) {
	      const { 
	        statusCode, 
	        headers, 
	        body 
	      } = error.response;
	      console.error(`Received ${statusCode} error response with payload: ${body}`);
	      const data = body ? JSON.parse(body) : {};
	      const message= data.message || data.error || "Unknown Error";

	      if (statusCode == 401) {
			console.error("Detected Authorization Error.");
			throw new SecurityError(message);
		  }
	      else throw new APIError(message, statusCode, data);
	    }
	  } else throw error;
	}
}

async function addAuthorization(apiName, options){
	if (apiName.includes('Public')) return options;
	const authToken= (await fetchAuthSession()).tokens?.idToken?.toString();
	return {... options, headers: { Authorization: authToken }};
}

